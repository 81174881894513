import React, { useState } from "react";
// import useSwr from "swr";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";

import createPlotlyComponent from "react-plotly.js/factory";
const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

export const Unitprice = () => {
    //
    const [unit, setUnit] = useState(null);
    const [keyword, setKeyword] = useState("鋼筋綁紮");
    const [treeData, setTreeData] = useState({ unit: [""], data: [] });

    const url = "https://bim.truedreams.com.tw/flask/api/unitprice";

    const fetcher = (url, req) =>
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: "Bearer " + sessionStorage.bimtoken,
            },
            method: "POST",
            body: JSON.stringify(req),
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("NG");
                }
                return response.json();
            })
            .then((data) => {
                if ((data["unit"].length <= 1) & (unit !== "")) {
                    data["unit"] = treeData["unit"];
                }
                setTreeData(data);
            })
            .catch((err) => {
                setTreeData(null);
            });

    const handleSubmit = (e) => {
        e.preventDefault();
        //
        fetcher(url, {
            ebudg_name: keyword,
            unit: unit,
            access_token: sessionStorage.bimtoken,
        });
        //
    };
    // unitprice
    const updata = [
        {
            x:
                treeData && treeData["data"]
                    ? treeData["data"].map((x) => x["CHG_DATE"])
                    : null,
            y:
                treeData && treeData["data"]
                    ? treeData["data"].map((x) => x["CHG_PRICE"])
                    : null,
            text: treeData["data"].map((x) =>
                "".concat(
                    "專案:",
                    x["PROJM_NO"],
                    "<br />",
                    "工項:",
                    x["EBUDG_NAME"],
                    "<br />",
                    "數量:",
                    x["CHG_QTY"],
                    "<br />",
                    "金額:",
                    x["CHG_AMT"],
                    "<br />",
                    "規格:",
                    x["EB_SPECI"],
                    "<br />",
                    "廠商:",
                    x["GUARANT2"],
                    "<br />",
                    "備註:",
                    x["REMARK_5"]
                )
            ),
            type: "scatter",
            mode: "markers",
            marker: { color: "deepskyblue" },
        },
    ];
    const uplayout = {
        autosize: true,
        title: "",
        xaxis: { title: "日期", type: "date" }, //, tickformat: "%Y-%m-%d"
        yaxis: { title: "單價" },
    };
    //
    return (
        <div>
            <div>
                <Form>
                    <Row className="align-items-center">
                        <Col sm={5} className="my-1">
                            <InputGroup>
                                <InputGroup.Text>🔎</InputGroup.Text>
                                <Form.Control
                                    id="keyword"
                                    placeholder="關鍵字"
                                    defaultValue={keyword}
                                    onChange={(e) => {
                                        setKeyword(e.target.value);
                                        setUnit("");
                                    }}
                                ></Form.Control>
                            </InputGroup>
                        </Col>
                        <Col sm={3} className="my-1">
                            {treeData && (
                                <Form.Control
                                    as="select"
                                    onChange={(e) => setUnit(e.target.value)}
                                >
                                    {treeData["unit"].map((variant, idx) => (
                                        <option>{variant}</option>
                                    ))}
                                </Form.Control>
                            )}
                        </Col>
                        <Col xs="auto" className="my-1">
                            <Button type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Plot
                    data={updata}
                    layout={uplayout}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                />
            </div>
        </div>
    );
};
