import React from "react";
import { Card } from "react-bootstrap";
import useSwr from "swr";

const fetcher = (url) =>
    fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        method: "GET",
    }).then((response) => {
        return response.json();
    });

export const Home = () => {
    const url = "https://bim.truedreams.com.tw/flask/api/" + "pexels";
    const { data, error } = useSwr(url, { fetcher });
    const treeData = data && !error ? data : null;
    const cardPhoto = treeData ? treeData["photo"] : null;
    const cardText = treeData
        ? "".concat(
              treeData["alt"],
              " by ",
              "photographer ",
              treeData["photographer"]
          )
        : null;
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card style={{ width: "30rem" }}>
                    <Card.Img variant="top" src={cardPhoto} />
                    <Card.Body>
                        <Card.Title>Curated Photo</Card.Title>
                        <Card.Text>{cardText}</Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <a href="https://www.pexels.com">
                    <small>Photos provided by Pexels</small>
                </a>
            </div>
        </div>
    );
};
