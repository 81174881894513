// https://www.npmjs.com/package/react-plotly.js
// https://github.com/plotly/react-plotly.js/issues/135
import React, { useState } from "react";
import useSwr from "swr";
import { Form } from "react-bootstrap";

import createPlotlyComponent from "react-plotly.js/factory";
const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

const data = { access_token: sessionStorage.bimtoken };
const fetcher = (url) =>
    fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + sessionStorage.bimtoken,
        },
        method: "POST",
        body: JSON.stringify(data),
    }).then((response) => {
        return response.json();
    });

export const AdminPlot = () => {
    //
    const [option, setOption] = useState("ageTruedreams");
    const url = "https://bim.truedreams.com.tw/flask/api/" + option;
    const { data, error } = useSwr(url, { fetcher });
    const treeData = data && !error ? data : [];
    // functions
    const agedataf = (opt) => {
        if (opt === "ageTruedreams") {
            return agedata;
        }
    };
    const agelayoutf = (opt) => {
        if (opt === "ageTruedreams") {
            return agelayout;
        }
    };
    // ageTruedreams
    const agedata = [
        {
            x: treeData.map((x) => x["年齡"]),
            y: treeData.map((x) => x["年資"]),
            text: treeData.map((x) =>
                "".concat(x["姓名"], "_", x["部門"], "_", x["職稱"])
            ),
            type: "scatter",
            mode: "markers",
            marker: { color: "blue" },
        },
    ];
    const agelayout = {
        autosize: true,
        // width: 800,
        // height: 600,
        title: "年齡 vs 年資",
        xaxis: { title: "年齡" },
        yaxis: { title: "年資" },
    };
    //
    return (
        <div>
            <div>
                <Form>
                    <Form.Group controlId="Form.ControlSelect1">
                        <Form.Label>Select:</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) => setOption(e.target.value)}
                        >
                            <option>ageTruedreams</option>
                            <option>reserved</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </div>
            <Plot
                data={agedataf(option)}
                layout={agelayoutf(option)}
                useResizeHandler={true}
                style={{ width: "100%", height: "100%" }}
            />
        </div>
    );
};
