// npm i react-bootstrap-table-next react-bootstrap-table2-paginator react-bootstrap-table2-toolkit
import React, { useState } from "react";
import useSwr from "swr";
import { Form } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const data = { access_token: sessionStorage.bimtoken };
const fetcher = (url) =>
    fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + sessionStorage.bimtoken,
        },
        method: "POST",
        body: JSON.stringify(data),
    }).then((response) => {
        return response.json();
    });

export const AdminTable = () => {
    //
    const [option, setOption] = useState("sopLogs");
    const url = "https://bim.truedreams.com.tw/flask/api/" + option;
    const { data, error } = useSwr(url, { fetcher });
    const treeData = data && !error ? data : [];
    //
    const cols = treeData.reduce(function (arr, o) {
        return Object.keys(o).reduce(function (a, k) {
            if (a.indexOf(k) === -1) a.push(k);
            return a;
        }, arr);
    }, []);
    const columns = cols.map((x) => {
        return { dataField: x, text: x };
    });
    // functions
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "All",
                value: treeData.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };
    //
    return (
        <div>
            <div>
                <Form>
                    <Form.Group controlId="Form.ControlSelect1">
                        <Form.Label>Select:</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) => setOption(e.target.value)}
                        >
                            <option>sopLogs</option>
                            <option>ageTruedreams</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </div>
            {data && !error && (
                <ToolkitProvider
                    keyField="id"
                    data={treeData}
                    columns={columns}
                    search
                >
                    {(props) => (
                        <div>
                            Input something:
                            <SearchBar {...props.searchProps} />
                            <hr />
                            <BootstrapTable
                                {...props.baseProps}
                                pagination={paginationFactory(options)}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            )}
        </div>
    );
};
