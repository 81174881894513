//https://juejin.im/entry/5b50518bf265da0f6436c34a
import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import { Home } from "./Home";
import { About } from "./About";
import { Contact } from "./Contact";
import { Login, Logout } from "./Login";
import { SOP } from "./SOP";
import { SiteMap } from "./SiteMap";
import { ForAdmin } from "./forAdmin";
import { AdminPlot } from "./AdminPlot";
import { AdminTable } from "./AdminTable";
import { TDtables } from "./TDtables";
import { Unitprice } from "./Unitprice";
import { Layout } from "./components/Layout";
import { NavigationBar } from "./components/NavigationBar";
// import { Jumbotron } from "./components/Jumbotron";
import { isLogin, isAdminLogin } from "./components/functions";
import { StateProvider } from "./components/StateProvider";

const initialState = {
    basename: "/sop",
    theme: {
        primary: "lightgreen",
    },
    login: isLogin(),
};
const reducer = (state, action) => {
    switch (action.type) {
        case "changeTheme":
            return {
                ...state,
                theme: action.newTheme,
            };
        case "changeLogin":
            return {
                ...state,
                login: action.newLogin,
            };
        default:
            return state;
    }
};

const App = () => {
    return (
        <React.Fragment>
            <StateProvider initialState={initialState} reducer={reducer}>
                <Router basename={initialState.basename}>
                    {/* <Router> */}
                    <NavigationBar />
                    {/* <Jumbotron /> */}
                    {/* <pre></pre> */}
                    <span style={{ padding: "1px" }}></span>
                    <Layout>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/about" component={About} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/login" component={Login} />
                            <Route path="/logout" component={Logout} />
                            {/* <Route path="/sitemap" component={SiteMap} /> */}
                            <Route
                                path="/sitemap"
                                render={() =>
                                    isLogin() ? (
                                        <SiteMap />
                                    ) : (
                                        <Redirect to="/login" />
                                    )
                                }
                            />
                            <Route
                                path="/forAdmin"
                                render={() =>
                                    isAdminLogin() ? (
                                        <ForAdmin />
                                    ) : (
                                        <div>無權限使用此功能</div>
                                    )
                                }
                            />
                            <Route
                                path="/AdminTable"
                                render={() =>
                                    isAdminLogin() ? (
                                        <AdminTable />
                                    ) : (
                                        <div>無權限使用此功能</div>
                                    )
                                }
                            />

                            <Route
                                path="/AdminPlot"
                                render={() =>
                                    isAdminLogin() ? (
                                        <AdminPlot />
                                    ) : (
                                        <div>無權限使用此功能</div>
                                    )
                                }
                            />
                            <Route
                                path="/SOP"
                                render={() =>
                                    isLogin() ? (
                                        <SOP />
                                    ) : (
                                        <Redirect to="/login" />
                                    )
                                }
                            />
                            <Route
                                path="/TDtables"
                                render={() =>
                                    isLogin() ? (
                                        <TDtables />
                                    ) : (
                                        <Redirect to="/login" />
                                    )
                                }
                            />
                            <Route
                                path="/Unitprice"
                                render={() =>
                                    isLogin() ? (
                                        <Unitprice />
                                    ) : (
                                        <Redirect to="/login" />
                                    )
                                }
                            />
                            <Redirect to="/" />
                        </Switch>
                    </Layout>
                </Router>
            </StateProvider>
        </React.Fragment>
    );
};

export default App;
