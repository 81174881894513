import React from 'react';
import jwt_decode from "jwt-decode";

function isLogin() {
    const token = !!sessionStorage.bimtoken && jwt_decode(sessionStorage.bimtoken).identity;
    //   console.log(token); *** */
    if (String(token).includes("TRUEDREAMS")) {
        return true;
    } else {
        return false;
    }
}

function SWRstatus(jdata){
    const { data, error }  = jdata;
    let result = 'ok';
    if (error) result =  <div>failed to load</div>;
    if (!data) result =   <div>loading...</div>;
    try {
        if (data["msg"] == "Token has expired") result =   <div>授權過期。請先登出，再登入!</div>;
    } catch (e) {
        // console.log(e);
    }    
    return result
}

function isAdminLogin() {
    const token =
        !!sessionStorage.bimtoken &&
        jwt_decode(sessionStorage.bimtoken).identity;
    console.log(token);
    //
    var result = false;

    if (String(token).includes("arga.huang")) {
        result = true;
    }
    //
    console.log("isAdminLogin result: ", result);
    //
    return result;
}

export { isLogin, isAdminLogin , SWRstatus};
