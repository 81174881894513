import React, { useState } from "react";
import { Form, Button, Col, Row, InputGroup, Dropdown } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
//
export const SOP = () => {
    //
    // const url = "api/sop2";
    const url = "https://bim.truedreams.com.tw/flask/api/sop2";
    //
    const [topic, setTopic] = useState(null);
    const [keyword, setKeyword] = useState(null);
    const [treeData, setTreeData] = useState(null);
    const [tableData, setTableData] = useState([{ A: null, B: null }]);
    //
    const fetcher = (url, req) => {
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: "Bearer " + sessionStorage.bimtoken,
            },
            method: "POST",
            body: JSON.stringify(req),
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("NG");
                    return {
                        error: response.status + ": " + response.statusText,
                    };
                }
                return response.json();
            })
            .then((data) => {
                setTreeData(data);
                // initialize Table
                if (data && "names" in data && data["names"].length > 0) {
                    let r = data["codes"].find(
                        (i) => i["name"] == data["names"][0]
                    );
                    setTableData(r["content"]);
                }
            })
            .catch((err) => {
                setTreeData({ error: err });
            });
    };
    //
    const handleSubmit = (e) => {
        e.preventDefault();
        //
        fetcher(url, {
            qname: topic,
            query: keyword,
            access_token: sessionStorage.bimtoken,
        });
        //
    };
    //
    const cols = tableData.reduce(function (arr, o) {
        return Object.keys(o).reduce(function (a, k) {
            if (a.indexOf(k) === -1) a.push(k);
            return a;
        }, arr);
    }, []);
    const columns = cols.map((x) => {
        return { dataField: x, text: x };
    });
    // functions
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "7",
                value: 7,
            },
            {
                text: "All",
                value: tableData.length,
            },
        ],
    };
    //
    const handleSelect = (e) => {
        e.preventDefault();
        //
        let r = treeData["codes"].find((i) => i["name"] == e.target.value);
        // console.log(r);
        setTableData(r["content"]);
    };
    //
    const SOPTable = (
        <ToolkitProvider keyField="id" data={tableData} columns={columns}>
            {(props) => (
                <div>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        striped
                    />
                </div>
            )}
        </ToolkitProvider>
    );
    //
    return (
        <div>
            <Form>
                <Row className="align-items-center">
                    <Col sm={3} className="my-1">
                        <InputGroup>
                            <InputGroup.Text>@</InputGroup.Text>
                            <Form.Control
                                id="topic"
                                placeholder="工項(TC)"
                                onChange={(e) => setTopic(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={3} className="my-1">
                        <Form.Control
                            id="keyword"
                            placeholder="SOP內文"
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </Col>
                    <Col xs="auto" className="my-1">
                        <Button type="submit" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div>
                <p>
                    {treeData && "error" in treeData
                        ? JSON.stringify(treeData["error"])
                        : null}{" "}
                </p>
                {/* <p>
                    {treeData && "names" in treeData
                        ? JSON.stringify(treeData["names"])
                        : null}{" "}
                </p> */}
                {treeData && "names" in treeData ? (
                    <div>
                        <div>
                            {/* <Dropdown>
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                                相關SOP
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {treeData["names"].map((variant, idx) => (
                                    <Dropdown.Item> {variant} </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown> */}
                        </div>
                        <Form.Control as="select" onChange={handleSelect}>
                            {treeData["names"].map((variant, idx) => (
                                <option> {variant} </option>
                            ))}
                        </Form.Control>
                        {SOPTable}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
