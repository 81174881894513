import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useStateValue } from "./StateProvider";

export const NavigationBar = () => {
    const [{ login, basename }] = useStateValue();

    let LoginComp;
    if (login) {
        LoginComp = <Nav.Link href={basename + "/logout"}>登出</Nav.Link>;
    } else {
        LoginComp = <Nav.Link href={basename + "/login"}>登入</Nav.Link>;
    }

    return (
        <Navbar expand="lg" variant="dark" bg="dark">
            <Navbar.Brand href={basename + "/"}>Truedreams Life</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href={basename + "/"}>Home</Nav.Link>
                    <Nav.Link href={basename + "/sitemap"}>Map</Nav.Link>
                    <NavDropdown title="SOP⁺" id="basic-nav-dropdown">
                        <NavDropdown.Item href={basename + "/SOP"}>
                            SOP
                        </NavDropdown.Item>
                        <NavDropdown.Item href={basename + "/Unitprice"}>
                            單價分佈
                        </NavDropdown.Item>
                        <NavDropdown.Item href={basename + "/TDtables"}>
                            其他查詢
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="forAdmin" id="basic-nav-dropdown">
                        <NavDropdown.Item href={basename + "/forAdmin"}>
                            json
                        </NavDropdown.Item>
                        <NavDropdown.Item href={basename + "/AdminTable"}>
                            table
                        </NavDropdown.Item>
                        <NavDropdown.Item href={basename + "/AdminPlot"}>
                            plot
                        </NavDropdown.Item>
                    </NavDropdown>{" "}
                    {LoginComp}
                    <Nav.Link href={basename + "/about"}>關於我們</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
