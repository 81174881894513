// npm i react-bootstrap-table-next react-bootstrap-table2-paginator react-bootstrap-table2-toolkit
// npm run build
import React, { useState } from "react";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
//
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
//
export const TDtables = () => {
    //
    const w5options = { 週五主管會議: "meeting5", "擴大工務會議 Nas:\\e.討論區\\01.月終擴大工務會議": "meetingm", "晨禎學苑 Nas:\\f.晨禎學苑": "TDcollege" };
    const [option, setOption] = useState("週五主管會議");
    const [keyword, setKeyword] = useState(null);
    const [treeData, setTreeData] = useState(null);
    // const url = "api/" + option;
    const url = "https://bim.truedreams.com.tw/flask/api/" + w5options[option];
    //
    const fetcher = (url, req) => {
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: "Bearer " + sessionStorage.bimtoken,
            },
            method: "POST",
            body: JSON.stringify(req),
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("NG");
                    return [
                        {
                            error: response.status + ": " + response.statusText,
                        },
                    ];
                }
                return response.json();
            })
            .then((data) => {
                // console.log("fetcher");
                // console.log(data);
                setTreeData(data);
            })
            .catch((err) => {
                setTreeData({ error: err });
            });
    };

    //
    const handleSubmit = (e) => {
        e.preventDefault();
        //
        fetcher(url, {
            query: keyword,
            access_token: sessionStorage.bimtoken,
        });
    };
    //
    // const cols = treeData.reduce(function (arr, o) {
    //     return Object.keys(o).reduce(function (a, k) {
    //         if (a.indexOf(k) === -1) a.push(k);
    //         return a;
    //     }, arr);
    // }, []);
    const cols = treeData ? treeData["columns"] : null;
    const columns = cols
        ? cols.map((x) => {
              return { dataField: x, text: x };
          })
        : null;
    // functions
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "All",
                value: treeData ? treeData["data"].length : 0,
            },
        ],
    };
    const SOPTable = () => {
        return (
            <ToolkitProvider
                keyField="id"
                data={treeData["data"]}
                columns={columns}
            >
                {(props) => (
                    <div>
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory(options)}
                            striped
                        />
                    </div>
                )}
            </ToolkitProvider>
        );
    };
    //
    return (
        <div>
            <div>
                <Form>
                    <Form.Group controlId="Form.ControlSelect1">
                        <InputGroup>
                            <InputGroup.Text>🔎</InputGroup.Text>
                            <Form.Control
                                as="select"
                                onChange={(e) => setOption(e.target.value)}
                            >
                                {Object.keys(w5options).map((variant, idx) => (
                                    <option>{variant}</option>
                                ))}                            </Form.Control>
                        </InputGroup>
                    </Form.Group>
                    <Row className="align-items-center">
                        <Col sm={3} className="my-1">
                            <InputGroup>
                                <InputGroup.Text>📄</InputGroup.Text>
                                <Form.Control
                                    id="keyword"
                                    placeholder="內文"
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="auto" className="my-1">
                            <Button type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <p>
                    {treeData && "error" in treeData
                        ? JSON.stringify(treeData["error"])
                        : null}{" "}
                </p>
                {treeData ? <div>{SOPTable()}</div> : null}{" "}
            </div>
        </div>
    );
};
