// https://www.npmjs.com/package/react-sortable-tree
// https://www.npmjs.com/package/react-json-view
// https://www.npmjs.com/package/searchable-react-json-view
//
import { circle } from "leaflet";
import React, { useState } from "react";
// import ReactJson from "react-json-view";
import JsonViewer from "searchable-react-json-view";
import useSwr from "swr";
import { Form, Button } from "react-bootstrap";

//
// const fetcher = (...args) => fetch(...args).then(response => response.json());
const data = { access_token: sessionStorage.bimtoken };
const fetcher = (url) =>
    fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + sessionStorage.bimtoken,
        },
        method: "POST",
        body: JSON.stringify(data),
    }).then((response) => {
        return response.json();
    });
//
export const ForAdmin = () => {
    const [option, setOption] = useState("training");
    const url = "https://bim.truedreams.com.tw/flask/api/" + option;
    const { data, error } = useSwr(url, { fetcher });
    const treeData = data && !error ? data : [];
    //
    const [search, setSearch] = useState(null);
    const [searchvalue, setSearchvalue] = useState(null);
    //
    // if (error) return <div>failed to load</div>;
    // if (!data) return <div>loading...</div>;
    //
    return (
        <div>
            <div>
                <Form>
                    <Form.Group controlId="Form.ControlSelect1">
                        <Form.Label>Select:</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) => setOption(e.target.value)}
                        >
                            <option>training</option>
                            <option>ageTruedreams</option>
                            <option>ad</option>
                            <option>reserved</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </div>
            {/* <div>
                <ReactJson
                    src={treeData}
                    theme={"monokai"}
                    name={false}
                    iconStyle={circle}
                    collapsed={true}
                    quotesOnKeys={false}
                    displayDataTypes={false}
                />
            </div> */}
            <div>
                <div>
                    Search:
                    <input
                        value={searchvalue}
                        onChange={(e) => setSearchvalue(e.target.value)}
                    />
                </div>
                <div>
                    <Button onClick={(e) => setSearch(searchvalue)}>
                        start to search
                    </Button>
                </div>
                <JsonViewer
                    highlightSearch={search}
                    // style={{ padding: "30px", backgroundColor: "white" }}
                    src={treeData}
                    theme={"monokai"}
                    quotesOnKeys={false}
                    collapseStringsAfterLength={12}
                    displayObjectSize={true}
                    name={false}
                    iconStyle={circle}
                    collapsed={true}
                    displayDataTypes={false}
                />
            </div>
        </div>
    );
};
