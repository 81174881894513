import React from "react";
import { Map, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import { Icon } from "leaflet";
// import * as parkData from "./assets/skateboard-parks.json";
import "./sitemap.css";
import useSwr from "swr";
import { SWRstatus } from "./components/functions";

// const fetcher = (...args) => fetch(...args).then(response => response.json());
const data = { access_token: sessionStorage.bimtoken };
const fetcher = (url) =>
    fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + sessionStorage.bimtoken,
        },
        method: "POST",
        body: JSON.stringify(data),
    }).then((response) => {
        // console.log(response);
        return response.json();
    });

export const SiteMap = () => {
    const [activePark, setActivePark] = React.useState(null);
    const url = "https://bim.truedreams.com.tw/flask/api/sitemap";
    const { data, error } = useSwr(url, { fetcher });

    let result = SWRstatus({ data, error });
    if (result != "ok") return result;
    const sites = data && !error ? data.slice(0, 1000) : [];

    return (
        <Map center={[24.152713, 120.680675]} zoom={12}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            {sites.map((site, index) => (
                <Marker
                    key={index}
                    position={[site["座標"][0], site["座標"][1]]}
                    onClick={() => {
                        setActivePark(site);
                    }}
                    // icon={icon}
                >
                    <Tooltip>{site["名稱"]}</Tooltip>
                </Marker>
            ))}

            {activePark && (
                <Popup
                    position={[activePark["座標"][0], activePark["座標"][1]]}
                    onClose={() => {
                        setActivePark(null);
                    }}
                >
                    <div>
                        <h4>{activePark["名稱"]}</h4>
                        <p>{activePark["地址"]}</p>
                        {/* <p>{activePark["座標"][0]},{activePark["座標"][1]}</p> */}
                        <a
                            href={
                                "https://www.google.com/maps/place/" +
                                activePark["座標"][0] +
                                "," +
                                activePark["座標"][1] +
                                "/@" +
                                activePark["座標"][0] +
                                "," +
                                activePark["座標"][1] +
                                ",15z"
                            }
                        >
                            {activePark["座標"][0]},{activePark["座標"][1]}
                        </a>
                    </div>
                </Popup>
            )}
        </Map>
    );
};
