// https://lzomedia.com/blog/how-to-create-snow-effects-for-this-christmas-with-tsparticles/#react-js
import React, { useState } from "react";
import styled from "styled-components";
// import jwt_decode from "jwt-decode";
import { useStateValue } from "./components/StateProvider";
// import Particles from "react-tsparticles";
// import snowConfig from "./assets/snow.json";

const Wrapper = styled.section`
    // margin: 0 auto;
    // width: 300px;
    font-size: 18px;
    text-align: center;
`;
const Div = styled.div``;
const Label = styled.label``;
const Input = styled.input``;
const Button = styled.button`
    margin-top: 10px;
`;
const Msg = styled.div`
    color: red;
    margin-top: 10px;
`;

function Login(props) {
    const [fetchedData, setFetchedData] = useState(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [{ login }, dispatch] = useStateValue();

    const handleSubmit = (e) => {
        e.preventDefault();
        setFetchedData(null);
        sessionStorage.removeItem("bimtoken");

        const data = {
            username: username,
            password: password,
        };

        fetch("https://bim.truedreams.com.tw/flask/users/login", {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    setFetchedData({ error: "Failed to validate!" });
                    // throw new Error("Failed to fetch!");
                }
                return response.json();
            })
            .then((data) => {
                setFetchedData(data);
                if (!!data.access_token) {
                    sessionStorage.setItem("bimtoken", data.access_token);
                }
                if (!!sessionStorage.bimtoken) {
                    dispatch({ type: "changeLogin", newLogin: true });
                    props.history.push("/home");
                }
            })
            .catch((err) => {
                console.log("error2 ", err, fetchedData);
                setFetchedData({ error: "Failed to validate!" });
            });
    };

    return (
        <Wrapper>
             {/*<Particles id="tsparticles" options={snowConfig} />*/}
            <Div>
                <Label htmlFor="username">username:&nbsp;</Label>
                <Input
                    type="text"
                    name="username"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Div>
            <Div>
                <Label htmlFor="password">password:&nbsp;</Label>
                <Input
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Div>
            <Button onClick={handleSubmit}>&nbsp;&nbsp;登入&nbsp;&nbsp;</Button>

            <Msg>
                {/* <Label>{JSON.stringify(fetchedData)} </Label> */}
                {/* {fetchedData && fetchedData.access_token && (
          <Label>{jwt_decode(fetchedData.access_token).identity} </Label>
        )} */}
                {fetchedData && fetchedData.error && (
                    <Label>{fetchedData.error} </Label>
                )}
            </Msg>
        </Wrapper>
    );
}

function Logout(props) {
    const [{ login }, dispatch] = useStateValue();

    const handleSubmit = (e) => {
        e.preventDefault();
        sessionStorage.removeItem("bimtoken");
        dispatch({ type: "changeLogin", newLogin: false });
        props.history.push("/home");
    };

    return (
        <Wrapper>
            <Button onClick={handleSubmit}>&nbsp;&nbsp;登出&nbsp;&nbsp;</Button>
        </Wrapper>
    );
}

export { Login, Logout };
