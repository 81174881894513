import React from "react";
import { Carousel } from "react-bootstrap";
import tdImage from "./assets/truedreams.jpg";
import ltImage from "./assets/livetrue.jpg";
import dwImage from "./assets/dewart.jpg";
//
export const About = () => {
    return (
        <div>
            <Carousel>
                {[
                    {
                        alt: "晨禎營造",
                        href: "https://www.truedreams.com.tw/index.php",
                        src: tdImage,
                    },
                    {
                        alt: "麗晨建設",
                        href: "https://www.livetrue.com.tw/",
                        src: ltImage,
                    },
                    {
                        alt: "晨露文化藝術基金會",
                        href: "https://dewart.org.tw/",
                        src: dwImage,
                    },
                ].map((x, idx) => (
                    <Carousel.Item>
                        <a href={x["href"]} target="_blank">
                            <img
                                className="d-block w-100"
                                src={x["src"]}
                                alt={x["alt"]}
                            />
                        </a>

                        <Carousel.Caption>
                            <h4>{x["alt"]}</h4>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};
